/* @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@100..900&family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100..700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap");

:root {
  /* пропорция */
  --e: 2.718281828459;
  --h1: max(1em, min(calc(var(--e) / 1 * 3.33vw), calc(var(--e) / 1 * 1em)));
  --h2: calc(var(--h1) / 1.5);

  /* цвета */
  --color_bg: hsl(0, 0%, 96%);

  --color_btn: hsl(0, 0%, 100%);
  --color_btn_hover: hsl(0, 0%, 10%);
  --color_border: hsl(0, 0%, 80%);

  --color_text: hsl(0, 0%, 0%);
  --color_text_hover: hsl(0, 0%, 100%);

  --color_text_2: hsl(0, 0%, 46%);
  --color_text_2_hover: hsl(100, 0%, 51%);

  --color_text_3: hsl(0, 0%, 58%, 0.5);

  --color_link: hsl(0, 0%, 4%);
  --color_link_hover: var(--color_btn_hover);
  --color_link-visited: #000;
  --color_link_visited_hover: var(--color_link_hover);
  --color_bg_popup: #0000005e;
  /* --color_border: var(--color_bg); */

  /* размеры кнопок */
  --bt_min: min(1rem, 20vw);
  --bt_pad_w: max(calc(18 / 16 * 16px), calc(var(--gap) * 2));
  /* --bt_pad_w: calc(var(--gap)*2); */
  --bt_pad_h: calc(10 / 16 * 16px);
  --border_thickness: min(1px, 0.1vmin);

  /* inside stroke */

  --bt_border: calc(var(--border_thickness) * 1) solid var(--color_border);
  --input_border: var(--border_thickness) solid var(--color_border);

  /* закругление */
  --round_bt: calc(var(--bt_min) * 1.5);
  /* --round_bt: min(calc(var(--bt_min)), var(--font_size)); */
  /* --round_bt: var(--font_size); */

  --round_input: calc(var(--round_bt) / 4);

  --font_size: min(16px, 10vw);
  --font_action: normal 1em "JetBrains Mono", monospace;
  --font_message: normal 1em "JetBrains Mono", monospace;
  --line_height: 1.5em;

  /* максимальное число символов на строку */
  --max_chars: 60;

  /* отступы */
  /* --padding_container: min(1rem, 10vw); */
  --padding_proportion: 24;
  --padding: calc(100vw / var(--padding_proportion));
  --padding_botom_phone: 20vh;
  --gap_proportion: 48;
  --gap: calc((100vw - var(--padding) * 2) / var(--gap_proportion));

  /* анимация */
  --animation_transition_hover: 0.2s ease;
  --animation_transition_unhover: 0.6s ease;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font: var(--font_message);
  line-height: 1;
  outline: none;
  vertical-align: baseline;
  align-content: center;
  /* align-items: center; */
  /* transition: var(--animation_transition_hover); */
}

@media (hover: hover) {
  button:hover,
  button:active,
  button:focus,
  input:hover,
  input:active,
  input:focus {
    color: var(--color_text_hover);
    background-color: var(--color_btn_hover);
    transition: var(--animation_transition_hover);
  }

  button,
  input {
    transition: var(--animation_transition_unhover);
  }

  input:hover::placeholder {
    color: var(--color_btn);
  }
}

path {
  fill: inherit;
  stroke: inherit;
}

body {
  background-color: var(--color_bg);
  color: var(--color_text);
  /* margin: 0; */
  /* margin: var(--bt_pad_w) calc(var(--bt_pad_w)) calc(var(--bt_pad_w) + var(--bt_min)); */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: var(--line_height);
}

input {
  background-color: var(--color_btn);
  background-color: var(--color_bg);
  font: var(--font_action);
  min-height: var(--bt_min);
  min-width: var(--bt_min);
  width: auto;
  text-align: center;
  padding: var(--bt_pad_h) var(--bt_pad_w);
  border: var(--input_border);
  border-radius: var(--round_input);

  text-align: left;
  white-space: pre-wrap;
  /* не позволяет перенос текста */
  overflow-wrap: break-word;
  /* не позволяет перенос текста */
}

button {
  color: var(--color_text);
  text-decoration: none;
  background-color: var(--color_btn);
  font: var(--font_action);
  min-height: var(--bt_min);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: var(--bt_min);
  padding: var(--bt_pad_h) var(--bt_pad_w) calc(var(--bt_pad_h) * 1.1);
  border: var(--bt_border);
  /* border-radius: var(--round_bt); */
  border-radius: var(--round_bt);
  font-size: var(--font_size);
  cursor: pointer;
}

button.selected {
  color: var(--color_text_hover);
  background-color: var(--color_btn_hover);
}

a {
  color: var(--color_link);
  text-decoration: none;
  cursor: pointer;
}

.container {
  /* background-color: var; */
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  /* background-color: hotpink; */
  padding: var(--padding);
  padding-bottom: var(--padding_botom_phone);
  width: 100%;
  /* margin: var(--padding); */
}

.scroll_wrapper {
  height: 100%;
  position: relative;
  min-height: calc(var(--bt_min) * 1.5);
  overflow-y: auto;
}

.scrollbar {
  height: 60px;
  width: 4px;
  background-color: var(--color_text);
  position: absolute;
  right: 0;
  z-index: 1000;
}

.welcome_content {
  flex: 1;
  align-content: flex-start;
  height: 100%;
  min-height: calc(var(--bt_min) * 1.5);
  overflow-y: auto;
  padding-bottom: calc(var(--bt_pad_h) + var(--bt_min));
}

.input_search_service {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.input_search_service input:focus {
  color: #fff;
  background-color: var(--color_btn_hover);
}

.input_search_service input:focus {
  background-color: var(--color_btn_hover);
}

h1 {
  font-family: var(--font_message);
  font-size: var(--h1);
  z-index: 2;
}

h2 {
  font-family: var(--font_message);
  font-size: var(--h2);
  z-index: 1;
}

.header {
  display: flex;
  flex-direction: column;
  gap: var(--padding);
  width: 100%;
  padding-bottom: var(--padding);
}

.header h1 {
  margin-left: calc(var(--bt_pad_w) - var(--h1) * 0.1);
}

.dynamic_bold {
  font-weight: 700;
}

.dynamic_display_none {
  display: none;
}

.media_container > img,
.media_container > video {
  max-width: 100%;
  height: auto;
}

.tos_button {
  width: 100%;
}

.markdown strong {
  font-weight: 700;
}

.markdown ol {
  margin-left: 35px;
  padding: 10px 0;
}

.markdown li {
  padding: 5px 0;
}

.markdown h3 {
  font-size: 1.5em;
  padding: 20px 0 10px;
}

.markdown a {
  color: var(--color_link);
  text-decoration: underline;
}
