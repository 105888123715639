ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.header {
  display: flex;
  flex-direction: column;
  /* position: sticky; */
  /* top: 0; */
  /* padding-top: 1rem; */
  /* margin-top: -1rem; */
  /* background: var(--color_bg); */
  gap: var(--padding);
  padding-bottom: var(--gap);
}

.header h1 {
  display: flex;
  flex-wrap: wrap;
  margin-left: var(--bt_pad_w);
}

.textPricePerHour {
  font-size: var(--font_size);
  color: var(--color_text_2);
  white-space: nowrap;
}

/* .header > h1 {
  font-weight: 400;
} */

.worker_list_page {
  padding: 1rem;
  padding-bottom: 20vh;
}

.filter_list {
  /* margin: 2rem 0; */
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--gap) * 0.5);
}

.worker_list li {
  /* border-bottom: 1px solid #000; */
  /* padding: 1rem 0; */
  padding: calc(var(--gap) /2) 0;
  /* margin: 0.25rem 0; */
}

.worker_list li:last-child {
  border: 0;
}

.bottom_nav {
  position: fixed;
  background: #fffd;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.25rem;
  width: 100%;
  justify-content: space-around;
  padding: 0.5rem;
}

.bottom_nav button {
  width: 100%;
}