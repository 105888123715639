.worker_page {
  padding: var(--bt_pad_w);
}

.worker_page h1 {
  margin-bottom: 2rem;
}

.worker_page > button {
  margin: 0.25rem 0;
}

.worker_page p {
  margin: 1.25rem 0;
}

.worker_page nav {
  position: fixed;
  background-color: #fff;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.25rem;
  width: 100%;
  justify-content: space-around;
  padding: 0.5rem;
}

.descriptionText {
  padding: var(--bt_pad_w);
}

.mediaList {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
