.worker_card {
  color: var(--color_text);
  font: var(--font_action);
  text-decoration: none;

  display: flex;
  flex-direction: column;
  gap: calc(var(--gap)*2);
  align-items: left;
  justify-items: center;

  min-height: var(--bt_min);
  min-width: var(--bt_min);

  padding: calc(var(--bt_pad_w));

  transition: 0s;
}

.worker_card.clickable {
  background-color: var(--color_btn);
  /* padding: var(--padding); */
  border: var(--bt_border);
  border-radius: var(--round_bt);
  cursor: pointer;
  transition: var(--animation_transition_unhover);
}

@media (hover: hover) {
  .worker_card.clickable:hover {
    color: var(--color_text_hover);
    background-color: var(--color_btn_hover);
    transition: var(--animation_transition_hover);
  }
}

.price_line {
  display: flex;
  font-size: calc(var(--h2));
  /* font-size: var(--font_size); */
  gap: var(--gap);
  /* padding-bottom: calc(var(--gap) / 2); */
  /* padding-bottom: calc(var(--bt_pad_h)); */
}

.or {
  color: var(--color_text_3);
  /* opacity: 0.5; */
  font-weight: 100;
}

.worker_statistics {
  display: inline-flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  /* justify-items: center; */
  gap: calc(var(--gap) / 1);
  /* justify-content: space-between; */
  /* align-items: center; */
  align-self: stretch;
}

.deals_line {
  display: flex;
  gap: var(--gap);
  padding-right: calc(var(--gap) * 2);
}

.worker_name {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  color: var(--color_text_2);
  /* padding-right: calc(var(--gap) * 2); */
}

.worker_card:hover .worker_name {
  color: var(--color_text_hover);
}

.color_text_2 {
  color: var(--color_text_2);
}

.color_text_3 {
  color: var(--color_text_2);
  opacity: 0.5;
  size: var(--font_size);
}

.worker_card:hover .color_text_2,
.worker_card:hover .or {
  color: var(--color_text_2_hover);
}

.image_container {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 5px;
}
.image {
  width: 40px;
  height: 40px;
}

.name_line {
  display: flex;
  gap: 10px;
  font-size: 1.5em;
}
