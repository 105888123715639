.inputContainer {
  width: 100%;
  padding: var(--bt_pad_h);
  display: flex;
  flex-direction: column;
  position: relative;
}

.inputContainer input {
  max-width: 100%;
  color: inherit;
  background-color: transparent;
  border: 0;
  padding: 0 5px;
}

.inputContainer input:hover {
  color: inherit;
  background-color: transparent;
}

.inputWrapper {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  gap: 5px;
  border-radius: 10px;
  border: var(--bt_border);
  transition: var(--animation_transition_unhover);
}

.inputWrapper:hover {
  cursor: text;
  transition: var(--animation_transition_hover);
}

.inputWrapper:hover,
.inputWrapper:focus-within {
  color: var(--color_text_hover);
  background-color: var(--color_btn_hover);
}

.selectedTag {
  padding: 5px 10px;
  border-radius: 5px;
  color: var(--color_text);
  border: var(--bt_border);
  background-color: var(--color_btn);
  transition: var(--animation_transition_unhover);
}

.selectedTag:hover {
  cursor: pointer;
  color: var(--color_text_hover);
  background-color: var(--color_btn_hover);
  transition: var(--animation_transition_hover);
}

.tagPanel {
  width: 100%;
  top: 100%;
  align-self: center;
  position: absolute;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--color_bg);
  display: flex;
  flex-direction: column;
  border: var(--bt_border);
}

.tagPanel > span {
  padding: 10px;
  font-size: 1.2em;
}

.tagPanel ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tagPanel li {
  border: var(--bt_border);
  background-color: var(--color_btn);
  border-radius: 5px;
  padding: 10px;
}

.tagPanel li:hover,
.tagPanel li.selected:hover {
  cursor: pointer;
  color: var(--color_text_hover);
  background-color: var(--color_btn_hover);
  transition: var(--animation_transition_hover);
}

.tagPanel li.selected {
  background-color: var(--color_text_3);
}
