.header_inner_text {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}

.price {
  font-size: var(--h2);
  margin-left: calc(var(--bt_pad_w) - var(--h1) * 0.1);
  color: var(--color_text_2);
}

.header_inner_buttons {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}

.blockRefLink {
  display: flex;
  flex-direction: column;
  gap: var(0.5em);
  padding-top: var(--font_size);
  padding-bottom: 100px;
}

.textRefLink {
  display: flex;
  flex-direction: column;
  gap: var(--font_size);
  padding: var(--bt_pad_w);
}

.ton_container {
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.history_pop_up {
  width: 100%;
  background-color: #ddd;
  border-radius: 10px;
}

.history_pop_up > h2 {
  padding: 10px;
}

.history_pop_up > ul, .history_header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 10px;
  gap: 5px;
}

.history_header {
  background-color: #aaa;
  margin-bottom: 5px;
}
