.nav_container {
  position: fixed;
  bottom: 0;
  left: 0;
  
  width: 100%;
  display: flex;
  gap: var(--gap);
  padding: var(--gap);
}

.nav_container > button {
  width: 100%;
  flex-grow: 1;
}
