.buttonList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.selectedButton {
  color: var(--color_text_hover);
  background-color: var(--color_btn_hover);
}
